import { Image } from "@nextui-org/image";
import { Card, CardBody, CardFooter } from "@nextui-org/card";
import { useTranslation } from "react-i18next";
import { Divider } from "@nextui-org/divider";
import { motion } from "framer-motion";
import Seo from "@components/Seo";
import { useGetPrices } from "src/services/services";
import { getStrapiImage } from "@utils/strapi.utils";

export interface TarifCardProps {
  title: string;
  description: string;
  price: number;
  studentPrice?: number;
  groupPrice?: number;
  duration?: string;
  benefits?: string[];
  image?: string;
}

const TarifCard = (props: TarifCardProps) => {
  const { image, title, description, price, studentPrice, groupPrice, duration, benefits } = props;
  return (
    <motion.div whileHover={{ scale: 1.03 }} transition={{ type: "spring", stiffness: 300 }} className="h-full">
      <Card className="h-full transition-shadow duration-300 shadow-sm border-1 border-neutral-200 hover:shadow-lg">
        <Image
          radius="none"
          alt="Card background"
          src={getStrapiImage(image)}
          width={900}
          className="h-[240px] object-cover brightness-95"
        />
        <CardBody className="flex flex-col items-center gap-3 p-0 overflow-hidden">
          <div className="flex flex-col items-center w-full p-6">
            <h3 className="mb-2 text-2xl font-medium text-center text-primary">{title}</h3>
            <Divider className="w-1/3 my-3 bg-secondary/30" />
            <p className="text-center text-gray-600">{description}</p>
            {duration && <p className="mt-3 text-sm text-secondary">Durée: {duration}</p>}
            <ul className="mt-4 space-y-2">
              {benefits?.map((benefit, i) => (
                <li key={i} className="flex items-center gap-2 text-sm text-gray-600">
                  <span className="text-secondary">✦</span> {benefit}
                </li>
              ))}
            </ul>
          </div>
        </CardBody>
        <CardFooter className="flex flex-col items-center gap-3 pt-2 pb-6">
          <div className="px-6 py-3 rounded-full bg-secondary/10">
            <p className="text-2xl font-medium text-secondary">{price} €</p>
          </div>
          {(studentPrice || groupPrice) && (
            <div className="flex flex-col items-center gap-1 text-sm text-gray-600">
              {studentPrice && <p>Tarif étudiant: {studentPrice} €*</p>}
              {groupPrice && <p>Tarif groupe (3+): {groupPrice} €/pers</p>}
            </div>
          )}
        </CardFooter>
      </Card>
    </motion.div>
  );
};

const Tarifs = () => {
  const { t, i18n } = useTranslation();
  const { data } = useGetPrices(i18n.language);

  if (!data) return null;

  return (
    <>
      <Seo
        title={t("pricing.title")}
        description={t("pricing.description")}
        type="Service"
        keywordsKey="pricing"
        author="Barbara Schluderbacher"
        schemaMarkup={{
          "@context": "https://schema.org",
          "@type": "Service",
          name: "Tarifs - Barbara Schluderbacher",
          description: t("pricing.description"),
          url: window.location.href,
          provider: {
            "@type": "Person",
            name: "Barbara Schluderbacher",
            address: {
              "@type": "PostalAddress",
              streetAddress: "7 rue sergent J Revel",
              addressLocality: "Jacob-Bellecombette",
              postalCode: "73000",
              addressCountry: "FR",
            },
          },
          offers: data.data.map(({ attributes }) => ({
            "@type": "Offer",
            itemOffered: {
              "@type": "Service",
              name: attributes.title,
              description: attributes.description,
            },
            price: attributes.price,
            priceCurrency: "EUR",
            availability: "https://schema.org/InStock",
            ...(attributes.studentPrice && {
              eligibleCustomerType: "http://schema.org/Student",
              price: attributes.studentPrice,
            }),
            ...(attributes.groupPrice && {
              eligibleQuantity: {
                "@type": "QuantitativeValue",
                minValue: 2,
              },
              price: attributes.groupPrice,
            }),
          })),
        }}
      />
      <main className="px-4 mt-16 mb-20 md:px-10 lg:px-20">
        <header className="pt-16 mb-12 text-center">
          <h1 className="mb-2 text-4xl tracking-widest md:text-5xl text-primary">{t("pricing.title")}</h1>
          <Divider className="w-32 mx-auto my-4 bg-secondary/30" />
          <p className="max-w-2xl mx-auto text-gray-600">{t("pricing.subtitle")}</p>
        </header>

        <section
          className="grid grid-cols-1 gap-6 mx-auto sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 md:gap-8"
          aria-label="Liste des tarifs"
        >
          {data.data.map((tarif, i) => (
            <TarifCard key={i} {...tarif.attributes} />
          ))}
        </section>
      </main>
    </>
  );
};

export default Tarifs;
